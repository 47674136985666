import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { GlobalStyle } from './GlobalStyle';

const Container = styled.div`
  position: relative;
`;

const Content = styled.div``;

export const Layout: FC = ({ children }) => (
  <Container>
    <GlobalStyle />
    <Helmet titleTemplate="%s · Musk Gold Rush" defaultTitle="Musk Gold Rush" />
    <Content>{children}</Content>
  </Container>
);
